<template>
  <div>
    <v-row class="mt-2">
      <v-col>
        <validation-provider v-slot="{ errors }" vid="startDate">
          <input-date
            v-model="filters.startDate"
            label="Data inicial"
            :error-messages="errors"
            solo
            dense
            flat
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col>
        <validation-provider v-slot="{ errors }" vid="endDate">
          <input-date
            v-model="filters.endDate"
            label="Data final"
            :error-messages="errors"
            solo
            dense
            flat
            clearable
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="countTasks"
          :loading="loadingTasks"
          :headers="headersTasks"
          :items="tasks"
          :options.sync="paginationTasks"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="loadTasks"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | date }}
          </template>
          <template v-slot:[`item.deliveryDeadlineDate`]="{ item }">
            {{ item.deliveryDeadlineDate | date }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="info"
                  class="ml-2"
                  small
                  icon
                  @click="openDialogViewTask(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-open-in-new'" small />
                </v-btn>
              </template>
              <small>Abrir</small>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <dialog-customer-task-view
      v-model="showDialogViewTask"
      :task-id="viewTaskId"
      is-customer
    />
  </div>
</template>

<script>
import customersDashboardApi from '@/api/customers-dashboard'

export default {
  components: {
    DialogCustomerTaskView: () =>
      import('@/components/dialog/customer-tasks/DialogCustomerTaskView'),
    InputDate: () => import('@/components/input/InputDate'),
  },

  data: () => ({
    loadingTasks: false,
    showDialogViewTask: false,
    viewTaskId: null,
    paginationTasks: {},
    headersTasks: [
      { text: 'Título', value: 'title' },
      { text: 'Data de cadastro', value: 'createdAt' },
      { text: 'Data de vencimento', value: 'deliveryDeadlineDate' },
      { text: 'Finalizada em', value: 'completedAt' },
      { text: '', value: 'actions', align: 'right' },
    ],
    countTasks: 0,
    tasks: [],
    filters: {},
  }),

  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadTasks()
      },
    },
  },

  methods: {
    async loadTasks() {
      try {
        this.loadingTasks = true

        const response = await customersDashboardApi.tasks({
          limit: this.paginationTasks.itemsPerPage,
          offset:
            this.paginationTasks.page * this.paginationTasks.itemsPerPage -
            this.paginationTasks.itemsPerPage,
          ...(this.filters.startDate
            ? { startDate: this.filters.startDate }
            : {}),
          ...(this.filters.endDate ? { endDate: this.filters.endDate } : {}),
        })

        this.tasks = response.data.tasks
        this.countTasks = response.data.count
      } catch (e) {
        console.log(e)
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingTasks = false
      }
    },

    openDialogViewTask(task = null) {
      this.viewTaskId = task ? task.id : null
      this.showDialogViewTask = true
    },
  },
}
</script>
